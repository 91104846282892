import React from 'react'
import styled from 'styled-components'
import SEO from '../components/seo'

const Container = styled.div`
  margin: 100px 0;
  min-height: 500px;
  display: grid;
  justify-content: center;
  align-content: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 300px;
  }
`

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </>
)

export default NotFoundPage
